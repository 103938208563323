import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WishListState } from './wish-list.reducer';

export const selectWishListStateFeature = createFeatureSelector<
    any,
    WishListState
>('wishListState');

export const getWishList = createSelector(
    selectWishListStateFeature,
    (state: WishListState) => state.wishList
);

export const checkIfTrekOnWishList = createSelector(
    selectWishListStateFeature,
    (state: WishListState, props: any) => {
        if (
            state.wishList &&
            'treks' in state.wishList &&
            Object.keys(state.wishList.treks!).includes(
                props.trek_id.toString()
            )
        ) {
            return state.wishList.treks![props.trek_id];
        }

        return false;
    }
);
